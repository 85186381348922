/// Represents default font color in the system
$catskill-White: #ECF1F6;
$outer-space: #223336;
$limed-spruce: #2c4246;
$blue-bayoux: #496472;
$black: #000000;
$white: #ffffff;
$cerulean: #0090E0;
$picton-blue: #49abe1;
$lochmara: #0083cb;
$gallery: #ebebeb;
$silver-chalice: #acacac;
$jungle-mist: #b6cad3;
$iron: #c9cfd3;
$wild-sand: #f5f5f5;
$alto: #DDDDDD;
$porcelain: #f0f2f3;
$mantis: #66c675;
$carrot-orange: #f59123;
$bright-orange: #df662b;
$regent-gray: #80939d;
$slate-gray: #70808e;
$flamingo: #f23f3f;
$blue-chill: #0d7bab;
$royal-blue: #3d83e1;
$blue-zodiac: #102250;
$forest-green: #248D34;
$alabaster: #fbfbfb;
$white-ice: #d8edf8;
$pearl-lusta: #FCF8E2;
$bizarre: #f2dede;
$peppermint: #dff0d7;
$apple: #3fb141;
$malibu: #81bfff;
$solitude: #e9f4ff;
$gray: #eee;
$tooltip-shadow: rgba(0, 0, 0, 0.33);
$white-light: #f2f4f5;
$blur-mith: #caedf3;
$vivid-navy: #5442ff;
$zi-row-hover:  #a8b6b9;
$grey-shadow: #d8d8d8;
$suva-grey: #8a8a8a;
$suva-grey: #6D6E71;
$blue-color: #3F7D95;
$lighten-bg-color: #49abe1;
$lighten-blue-color: #0090e0;
$dark-blue-color: #227d9e;
$gray-text-color: #6D6E71;
$main-text: #404040;
$lighten-gray-color: #f0eff0;
$lighten-gray-text-color: lighten($gray-text-color, 20%);
$orange-color: #F3703A;
$dark-orange-color: #D86433;
$yellow-color: #FEBC11;
$dark-yellow-color: #DFA611;
$green-color: #42bc4e;
$error-red-color: #CE450A;
$dark-red-color: #BF1D30;
$buttons-red-color: #F44238;

$light-green: #7fcbac;
$dark-green: #00586E;
$light-bluecolor: #49ACE1;
$dark-blue: #2271bf;
$very-dark-blue: #14599d;
$light-gray: #7c7c7c;
$lighter-gray: #6D7D8F;
$dark-gray: #1a1a1a;
$link-color: #4a6ee3;
$link-btn:  #0061FF; //#0D78F9;
$purple-dark: #5482E8;
$gray-dark: #28282c;
$blue-dark: #263a3e;
$floral-white: #fefdfb;
$nero: #292929;
$cornflower-blue:  #c9c8ce;

$button-red-bg-color: #E50C00;
$button-red-bg-hover-color: #990606;
$button-red-go-back-bg-color: #EA1B15;
