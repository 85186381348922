@import "colors";

// From Grow App

$variable-text-color: $black;
$primary-active-color: $cerulean;
$hover-active-color: $blue-chill;
$icon-color-regular: $blue-bayoux;
$white-bg: $white;
$white-font-color: $white;


$main-bg: $catskill-White;
$main-text: $outer-space;
$main-text-disabled: $silver-chalice;
$header-title: $limed-spruce;
$side-menu-bg: $limed-spruce;
$side-menu-btn-hover-bg: $blue-bayoux;
$slider-shadow: $black;
$side-menu-icon: $white;
$primary-btn-text: $white;
$primary-btn-bg: $cerulean;
$primary-btn-bg-hover: $picton-blue;
$primary-btn-bg-click: $lochmara;
$primary-btn-bg-disabled: $gallery;
$primary-btn-text-disabled: $silver-chalice;
$divider-gray-bg: $jungle-mist;
$divider-white-bg: $alto;
$card-bg-shadow: $iron;
$action-btn-bg-hover: $wild-sand;
$secondary-btn-border: $alto;
$secondary-btn-text: $outer-space;
$secondary-btn-bg: $white;
$secondary-btn-bg-hover: $porcelain;
$secondary-btn-bg-click: $silver-chalice;
$secondary-btn-bg-disabled: $wild-sand;
$secondary-btn-text-disabled: $silver-chalice;
$import-icon-connection: $mantis;
$import-icon-connection-error: $carrot-orange;
$chips-clear-bg: $regent-gray;
$expander-selected-bg: $slate-gray;
$error-color: $flamingo;
$pagination-pages-hover: $blue-chill;
$export-salesloft: $royal-blue;
$export-dynamics: $blue-zodiac;
$export-excel: $forest-green;
$export-outreach: $vivid-navy;
$export-hubspot: $bright-orange;
$export-salesforce: $cerulean;
$export-icon-default: $blue-bayoux;
$contact-details-bg: $alabaster;
$noty-info-bg: $white-ice;
$noty-warning-bg: $pearl-lusta;
$noty-error-bg: $bizarre;
$noty-success-bg: $peppermint;
$noty-default-bg: $wild-sand;
$noty-success-icon: $apple;
$empty-state-1: $malibu;
$empty-state-2: $solitude;
$primary-text-color: #010D39;
$secondary-text-color: #546B8F;
$borders-color: $alto;
$primary-active-color: $cerulean;
$blue-link-color: #005BFB;
$blue-link-hover-color: #0041c0;
$error-text-color: #CF0636;

$bar-chart-fg: $white-ice;
$bar-chart-bg: $malibu;
$warning-text-color: $flamingo;

$content-box-bg: $white;

$table-bg: $white;

$dropdown-bg-active: #caedf3;
$dropdown-bg-hover: #f2f4f5;
$separator-color: #a7c4ca;
$list-row-hover: $porcelain;
$source-icon:  #496472;
$back-button: $blue-bayoux;
$back-button-hover: $outer-space;
$disable-state: $solitude;

$submit-button-bg: $button-red-bg-color;
$submit-button-bg-disabled: #F2F5FA;
$disabled-input-color: $silver-chalice;

$textbox-border-color-default: #6D84AB;
$textbox-border-color-invalid: #CF0636;

// Icons

$main-icon-color: $blue-bayoux;

// Dialogs variables
$dialog-header-border-color: $purple-dark;
$dialog-header-close-btn: $gray-dark;
$dialog-content-text: $blue-dark;
$dialog-footer-bg: $wild-sand;

$filters-header-bg: $porcelain;
$filter-header-shadow:$jungle-mist;
