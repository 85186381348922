@import "mixins";
@import "variables";
@import "colors";
@import "font";
@import "layout";
@import "reset";

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: $white-bg;
  font-family: 'Poppins', sans-serif !important;
  line-height: 1.5;

  h1, h2 {
    font-weight: $font-weight-semi-bold;
  }

  a {
    color: $link-color;
  }

  button {
    flex-grow: 0;
    font-size: $font-base;
  }

  input, textarea {
    @include font-style($font-small, $black, $font-weight-normal);
    width: 100%;
    height: 30px;
    border-radius: 2px;
    border: 1px solid $textbox-border-color-default;
    padding-left: 10px;
    &:focus, button:focus {
      outline: none;
    }
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px white inset;
    }
    &.ng-valid.submitted, &.ng-valid.ng-dirty, &.ng-valid.ng-touched {
      border: 1px solid $textbox-border-color-default;
    }
    &.ng-invalid.submitted, &.ng-invalid.ng-touched {
      border: 1px solid $textbox-border-color-invalid;
    }
  }
}

input[type=text], input[type=password], input[type=email], input[type=tel] {
  &:focus {
    border: 2px solid #005bfb;
  }
  &::placeholder {
    color: #546B8F;
  }
}

.logo-anchor {
  &:focus {
    outline: 2px solid #005BFB;
    outline-offset: 2px;
    border-radius: 4px;
  }
}

.reg-btn {
  border: none;
  border-radius: 100px;
  font-size: $font-medium;
  height: 44px;
  &:disabled {
    cursor: auto;
  }
  &:focus {
    outline: 2px solid #005BFB;
    outline-offset: 2px;
  }
}

.red-btn {
  background-color: $button-red-bg-color;
  color: white;
  &:disabled {
    background-color: #F2F5FA;
    color: #BCC9DE;
  }
  &:hover:enabled {
    background-color: $button-red-bg-hover-color;
  }
}

.lineBreak-10px {
  display: block;
  height: 10px;
}

.lineBreak-20px {
  display: block;
  height: 20px;
}

.new-app-launcher {
  width: 100vw !important;
  height: 100vh !important;
  max-width: unset !important;
}

.old-app-launcher {
  width: 550px !important;
}

.background-default {
  background: linear-gradient(32.63deg, #000514 0%, #010D39 53.48%, #EA1B15 100%);
}

.background-copilot {
  background: linear-gradient(27.04deg, #000514 22.89%, #202B52 99.01%);
}

.union-bg {
  background-position-x: right;
  background-position-y: top;
  background-repeat: no-repeat;
}

.union-default-bg {
  background-image: url('/assets/images/login/default-union-background.svg');
}

.union-copilot-bg {
  background-image: url('/assets/images/login/copilot-union-background.svg');
}

.padding-22px {
  padding: 22px;
}

.logo-gap {
  gap: 6.818px;
}

.height-48px {
  height: 48px;
}

.card-spinner-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  @include flex(center, center);
  z-index: 1;
}
