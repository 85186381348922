/* You can add global styles to this file, and also import other style files */
@import "styles/base";
@import "styles/mixins";
@import "styles/layout";
@import "styles/variables";
@import "styles/font";

@import '~intl-tel-input/build/css/intlTelInput.css';
@import '~@angular/material/prebuilt-themes/indigo-pink.css'; // Important for Dialog!!
@import '~primeng/resources/primeng.min.css';
@import '../node_modules/primeng/resources/themes/saga-blue/theme.css';
@import '~primeicons/primeicons.css';

/* Code take from: https://www.paulirish.com/2012/box-sizing-border-box-ftw/ */
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
  font-family: $font-family;
}

// p-dropdown from primeng
.p-dropdown.p-component {
  border: 1px solid #6d84ab;
  border-radius: 6px;
  box-shadow: none !important;
  text-align: left;
  width: 100%;
  &:hover {
    border: 1px solid #6d84ab;
  }
  &.p-focus, &.p-dropdown-open, &:focus {
    border: 1px solid #005BFB;
    box-shadow: 0px 0px 0px 1px #005BFB !important;
  }
  &.p-disabled {
    border: 1px solid #BDCDE7;
    background-color: #F2F5FA;
    opacity: 1;
    .p-dropdown-label.p-inputtext, .p-dropdown-trigger-icon.pi.pi-chevron-down {
      color: #BCC9DE;
    }
  }
  .p-dropdown-label.p-inputtext, .p-dropdown-filter.p-inputtext.p-component, .p-dropdown-item.p-ripple {
    color: $primary-text-color;
    font-family: $font-family;
  }
  .p-dropdown-label.p-inputtext {
    font-size: 14px;
    height: 44px;
    line-height: 22px;
    padding: 11px 12px;
  }
  .p-dropdown-filter-container {
    display: flex;
    flex-direction: row;
    .p-dropdown-filter.p-inputtext.p-component {
      border: 1px solid #DCE7FA;
      border-radius: 6px 6px 0 0;
      height: 48px;
      padding: 0 10px 0 48px;  // 20px search icon padding + 16px icon width + 12px between icon and text = 48px
      &:focus {
        box-shadow: none;
        border-bottom: 1px solid #005BFB;
      }
    }
    // search icon
    .p-dropdown-filter-icon.p-icon {
      left: 20px;  // figma shows 16px + 4px padding
      top: 48%;
    }
    .close-icon {
      align-items: center;
      background: none;
      border: none;
      cursor: pointer;
      display: flex;
      padding: 0;
      position: absolute;
      right: 12px;
      top: 12px;
      &:focus {
        outline: 1px solid #005BFB;
        outline-offset: 1px;
      }
    }
  }
  .p-dropdown-filter.p-inputtext.p-component, .p-dropdown-item.p-ripple {
    font-size: 12px;
    line-height: 18px;
  }
  .p-dropdown-trigger-icon.pi.pi-chevron-down::before, .p-dropdown-filter-icon.pi.pi-search::before {
    font-family: 'primeicons', sans-serif;
    font-size: 14px;
  }
  .p-dropdown-panel.p-component {
    align-items: center;
    border: 1px solid #DCE7FA;
    border-top: none;
    border-radius: 6px;
    box-shadow: 0px 7px 16px rgba(64, 84, 134, 0.13);
    display: flex;
    flex-direction: column;
    left: -1px !important;
    margin-top: 8px;
    width: 320px;
  }
  .p-dropdown-header {
    background: none !important;
    padding: 0 !important;
    width: 320px;
  }
  .p-dropdown-items-wrapper {
    margin-top: 8px;
    width: 320px;
    .p-dropdown-item.p-ripple {
      padding: 9px 16px;
      &:not(.p-disabled):hover {  // hovered item
        background: #F7F9FF;
      }
      &:not(.p-highlight):not(.p-disabled):hover {  // non-selected hovered item
        color: #010D39;
      }
    }
    .p-dropdown-item.p-highlight.p-ripple {  // selected item
      align-items: center;
      background: none;
      color: #005BFB;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &::after {
        background-image: url('/assets/images/login/regular-2fa-setup/dropdown-checkmark.svg');
        background-repeat: no-repeat;
        content: '';
        height: 10px;
        margin-right: 5px;
        width: 14px;
      }
    }
  }
}

@media screen and (max-width: 802px) {
  .p-dropdown-panel.p-component {
    width: 300px !important;
    .p-dropdown-header, .p-dropdown-items-wrapper {
      width: 300px;
    }
  }
}
@media screen and (max-width: 370px) {
  .p-dropdown-panel.p-component {
    width: 268px !important;
    .p-dropdown-header, .p-dropdown-items-wrapper {
      width: 268px;
    }
  }
}

body {
  padding: 0;
  margin: 0;
  .chzn-container.chzn-container-single.chzn-with-drop.chzn-container-active {
    .chzn-single {
      span {
        font-size: 0.86rem;
      }
    }
    .chzn-drop {
      .chzn-results {
        .active-result {
          font-size: 0.86rem;
        }
      }
    }
  }
  .chzn-container.chzn-container-single.chzn-container-single-nosearch.chzn-with-drop.chzn-container-active {
    .chzn-drop {
      .chzn-results {
        .active-result.result-selected {
          color: #005BFB;
        }
        // checkmark if selected
        .active-result.result-selected::after {
          position: absolute;
          content: '';
          background-image: url('/assets/images/login/regular-2fa-setup/okta-dropdown-selected-checkmark.png');
          background-repeat: no-repeat;
          width: 16px;
          height: 10px;
          right: 26px;
        }
      }
    }
  }
}

// intl-tel-input design
.intl-tel-input {
  width: 100% !important;
  z-index: 3;
  input {
    width: 100%;
    border: 1.5px solid #eaeaef;
    padding: 0 10px;
    height: 46px;
    border-radius: 20px;
    margin-bottom: 2px;
    font-size: $font-medium;
    font-weight: 400;
    &::placeholder {
      color: transparent;
    }
  }
  .selected-flag {
    outline: none;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .country-name {
    color: black !important;
  }
  .country-list {
    position: static !important;
  }
}

.intl-tel-input.allow-dropdown .flag-container, .intl-tel-input.separate-dial-code .flag-container {
  width: 100% !important;
}

mat-dialog-container.mat-dialog-container {
  padding: 0;
  min-width: fit-content;
}

.ada-float-left {
  right: unset;
  left: 0;
}

@media screen and (max-width: 802px) {
  #ada-chat-frame {
    bottom: 16px !important;
    right: 16px !important;
  }
}

@media screen and (max-width: 400px) {
  #ada-chat-frame {
    width: 290px !important;
    .sc-fhnvib.iptAem {
      min-width: auto;
    }
  }
}

@media screen and (min-height: 760px) {
  html {
    overflow-y: hidden;
  }
}
